import type { SWRConfiguration } from 'swr';
import type { SWRInfiniteKeyLoader } from 'swr/infinite';

import useSWRInfinite from 'swr/infinite';

import fetcher from './fetcher';
import useUnauthorizedRedirect from './useUnauthorizedRedirect';

/**
 * Wraps [useSWRInfinite](https://swr.vercel.app/docs/pagination#useswrinfinite)
 * with the same fetcher function and SWR default options that we use in the `useReadmeApi` hook.
 */
function useReadmeApiInfinite<Data>(getKey: SWRInfiniteKeyLoader, options: { swr?: SWRConfiguration } = {}) {
  const { swr: swrOptions = {} } = options || {};

  // NOTE: These SWR options are using default values different than SWRs.
  // https://swr.vercel.app/docs/options#options
  swrOptions.revalidateOnMount ??= true;
  swrOptions.revalidateOnFocus ??= false;
  swrOptions.shouldRetryOnError ??= false;

  const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite<Data>(getKey, fetcher, swrOptions);

  useUnauthorizedRedirect(error);

  return {
    data,
    error,

    /**
     * True when fetching is complete and a valid `data` or `error` object has
     * been set, allowing either to be parsed and displayed to the user. Use
     * this property to conditionally display a loading state in the UI.
     */
    isLoading: (!error && !data) || false,

    /**
     * True if there's a request or revalidation loading via SWR
     */
    isValidating,

    /**
     * Use this to perform mutations on the current data set. See
     * https://swr.vercel.app/docs/mutation for more patterns on how to
     * configure optimistic updates and auto-rollbacks on error.
     */
    mutate,

    /**
     * The number of pages that will be fetched and returned
     */
    size,

    /**
     * Set the number of pages that need to be fetched
     */
    setSize,
  };
}

export default useReadmeApiInfinite;
