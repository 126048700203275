import type { ReferenceLanguageSlice, ReferenceLanguageSlicePersistedState } from './Language/types';
import type { $TSFixMe } from '@readme/iso';
import type { HarRequest } from '@readme/oas-to-snippet/types';

import { createStore } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { actionLog, createBoundedUseStore, isClient } from '@core/store/util';

import { createReferenceAuthSlice, type ReferenceAuthSlice } from './Auth';
import { createReferenceFormSlice, type ReferenceFormSlice } from './Form';
import { createReferenceLanguageSlice } from './Language';
import { languageCookieStorage } from './Language/helpers';

type SelectedHar = HarRequest & Record<string, $TSFixMe>;

interface ReferenceStoreState {
  /**
   * Indicates whether the reference store is fully hydrated with incoming data
   * either from our SSR props, API endpoint or some other call to reset() that
   * moves us out of our "default" starting state.
   */
  isReady: boolean;

  /**
   * Selected HAR, used in metrics log picker
   */
  selectedHar?: SelectedHar | null;
}

interface ReferenceStoreActions {
  /**
   * Initializes reference store. Used by `InitializeReferenceStore`.
   */
  initialize: (
    opts: Parameters<ReferenceAuthSlice['auth']['initialize']>[0] &
      Parameters<ReferenceFormSlice['form']['initialize']>[0] &
      Parameters<ReferenceLanguageSlice['language']['initialize']>[0],
  ) => void;

  /**
   * Resets state back to the last "initialized" state. When `partialState` is
   * provided, it is merged into state and re-initialized to this.
   */
  reset: () => void;
  updateSelectedHar: (har: ReferenceStoreState['selectedHar']) => void;
}

export type ReferenceStore = ReferenceAuthSlice &
  ReferenceFormSlice &
  ReferenceLanguageSlice &
  ReferenceStoreActions &
  ReferenceStoreState;

const initialState: ReferenceStoreState = {
  isReady: false,
  selectedHar: null,
};

export const referenceStore = createStore<ReferenceStore>()(
  devtools(
    immer(
      persist(
        (set, get, ...props) => {
          /**
           * Holds reference to the initial state so we can support resetting the
           * store back to this state when calling `reset()`.
           */
          const resetState = {
            ...initialState,
            ...createReferenceLanguageSlice(set, get, ...props),
            ...createReferenceFormSlice(set, get, ...props),
            ...createReferenceAuthSlice(set, get, ...props),
          };

          return {
            ...resetState,

            initialize: ({
              apiDefinition,
              auth,
              availableLanguages,
              customCodeSamples,
              fullServerUrl,
              group,
              groupName,
              groups,
              isGroupLoggedIn,
              isOAuthRedirectPage,
              language,
              maxLanguages,
              oauth,
              operation,
              providedLanguages,
              selectedAuth,
              server,
              supportedLanguages,
              supportsSimpleMode,
              useAllAvailableLanguages,
            }) => {
              get().language.initialize({
                availableLanguages,
                customCodeSamples,
                language,
                maxLanguages,
                providedLanguages,
                supportedLanguages,
                supportsSimpleMode,
                useAllAvailableLanguages,
              });

              // we only initialize the server data on initial page load
              if (!get().isReady) {
                get().form.initialize({ fullServerUrl, server });
              } else {
                // if not on initial page load, we re-initialize using the existing server variable data
                get().form.initialize({
                  fullServerUrl: get().form.fullServerUrl,
                  server: get().form.schemaEditor.data.server,
                });
              }

              get().auth.initialize({
                apiDefinition,
                auth,
                group,
                groupName,
                groups,
                isGroupLoggedIn,
                isOAuthRedirectPage,
                oauth,
                operation,
                selectedAuth,
              });

              set(
                state => {
                  // When running on the server, we must avoid marking this store
                  // as "ready" to ensure it continues receiving updates until it
                  // gets initialized on the client's first render.
                  if (isClient) {
                    state.isReady = true;
                  }
                },
                false,
                actionLog('initialize'),
              );
            },

            reset: () => {
              set(resetState, false, actionLog('reset'));
            },

            updateSelectedHar: har => {
              set(
                state => {
                  state.selectedHar = har;
                },
                false,
                actionLog('updateSelectedHar', har),
              );
            },
          };
        },
        {
          name: 'ReferenceStore',
          partialize: (state): ReferenceLanguageSlicePersistedState => {
            const { language, languageLibrary } = state.language;
            return { language: { language, languageLibrary } };
          },
          skipHydration: true,
          storage: createJSONStorage(() => languageCookieStorage),
        },
      ),
    ),
    { name: 'ReferenceStore' },
  ),
);

export const useReferenceStore = createBoundedUseStore(referenceStore);

export * from './InitializeReferenceStore';
