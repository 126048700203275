import type { ClientPlugin } from '@readme/httpsnippet/targets';

import httpsnippetClientAPIPlugin from 'httpsnippet-client-api';
import { useEffect, useState } from 'react';

export default function useHTTPSnippetPlugins() {
  const [httpsnippetPlugins, setHttpsnippetPlugins] = useState<ClientPlugin[]>([httpsnippetClientAPIPlugin]);

  useEffect(() => {
    const customPlugin = window.foo;
    if (customPlugin) {
      setHttpsnippetPlugins(c => [...c, customPlugin]);
    }
  }, []);

  return { httpsnippetPlugins };
}
