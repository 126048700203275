import type { ReferenceAuthSliceState } from './Auth';
import type { ReferenceFormSliceState } from './Form';
import type { CustomCodeSamples } from './Language/types';
import type { SupportedTargets } from '@readme/oas-to-snippet/languages';
import type Oas from 'oas';
import type { Operation } from 'oas/operation';

import { getGroupNameById, operationSupportsSecurityScheme } from '@readme/iso';
import { getSupportedLanguages } from '@readme/oas-to-snippet/languages';
// eslint-disable-next-line readme-internal/no-restricted-imports
import { getGroupId, getIdFromKey } from '@readme/server-shared/metrics/getGroupId';
import { CODE_SAMPLES, SAMPLES_LANGUAGES, SIMPLE_MODE } from 'oas/extensions';
import React, { useContext, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import type { ProjectContextValue, VariablesContextValue } from '@core/context';
import { ProjectContext, VariablesContext } from '@core/context';
import useAuthStorage, { usePersistAuthStorage } from '@core/hooks/useAuthStorage';
import useHTTPSnippetPlugins from '@core/hooks/useHTTPSnippetPlugins';

import { allLanguages, sortLanguages, TREAT_AS_LANGUAGE } from './Language/helpers';

import { useReferenceStore } from '.';

export interface InitializeReferenceStoreProps {
  /**
   * The current API definition
   */
  apiDefinition?: Oas;

  /**
   * We pass through any child components without reading/modifying them.
   */
  children: React.ReactNode;

  /**
   * Flag to indicate whether or not the current page is the OAuth redirect page.
   * If so, the OAuth state/status will be updated accordingly.
   */
  isOAuthRedirectPage?: boolean;

  /**
   * The current language. If this is omitted,
   * we let the store determine this
   * @example shell
   */
  language?: SupportedTargets;

  /**
   * Array of language strings. If this is empty/omitted,
   * we extrapolate this from the API definition
   * @example ['shell', 'node', 'ruby', 'php', 'python', 'java', 'csharp']
   */
  languages?: string[];

  /**
   * The maximum number icons that can appear in the language picker
   */
  maxLanguages?: 5 | 7;

  /**
   * The current OpenAPI operation
   */
  operation?: Operation;

  /**
   * Allows for explicitly configuring whether or not to enable simple mode.
   */
  simpleMode?: boolean;

  /**
   * Flag to control that all the available languages that a user has access to come from **all**
   * of the languages that we support code snippet generation for by way of our
   * `@readme/oas-to-snippet` package.
   *
   * If false, only the languages that are supplied to the `languages` prop are the ones that user
   * will see -- though any languages within that list must adhere to languages that we support.
   * You can't toss in a language that we don't support and expect it to work!
   */
  useAllAvailableLanguages?: boolean;
}

/**
 * Middleware component listens for Hub browser router updates and continually
 * updates the store state whenever the route changes.
 */
export function InitializeReferenceStore({
  apiDefinition,
  children,
  isOAuthRedirectPage,
  language,
  languages = [],
  maxLanguages = 5,
  operation,
  simpleMode,
  useAllAvailableLanguages = true,
}: InitializeReferenceStoreProps) {
  const [initialize, isReady, currentGroupName, currentSelectedAuth, updateAuth, updateGroup, updateServer] =
    useReferenceStore(s => [
      s.initialize,
      s.isReady,
      s.auth.groupName,
      s.auth.selectedAuth,
      s.auth.updateAuth,
      s.auth.updateGroup,
      s.form.updateServer,
    ]);

  const { project } = useContext(ProjectContext) as ProjectContextValue;

  const { httpsnippetPlugins } = useHTTPSnippetPlugins();

  const supportedLanguages = structuredClone(
    getSupportedLanguages({
      plugins: httpsnippetPlugins,
    }),
  );

  const { user } = useContext(VariablesContext) as VariablesContextValue;

  // initialize data specific to language slice
  const supportsSimpleMode = useMemo(() => {
    if (simpleMode !== undefined) return simpleMode;

    if (project?.appearance && 'referenceSimpleMode' in project.appearance) {
      if (!project.appearance.referenceSimpleMode) {
        return false;
      }
    }

    return apiDefinition ? !!apiDefinition.getExtension(SIMPLE_MODE, operation) : true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiDefinition?.api?._id, operation, project]);

  const initialLanguages = useMemo(
    () => {
      return languages.length || !apiDefinition
        ? languages
        : (apiDefinition.getExtension(SAMPLES_LANGUAGES, operation) as string[]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiDefinition?.api?._id, languages, operation],
  );

  const customCodeSamples = useMemo(() => {
    return apiDefinition ? (apiDefinition.getExtension(CODE_SAMPLES, operation) as CustomCodeSamples) : false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiDefinition?.api?._id, operation]);

  const [availableLanguages, providedLanguages] = useMemo(() => {
    const normalized = initialLanguages.map(lang => {
      const lowerCaseLang = lang.toLowerCase();
      if (lowerCaseLang === 'node-simple') {
        return 'node';
      } else if (lowerCaseLang === 'curl') {
        return 'shell';
      }

      return lowerCaseLang;
    });

    // We might have duplicates in the incoming languages so we're converting it into a unique
    // set and then back again to filter them out.
    const provided = [...new Set(normalized)]
      .map(lang => {
        if (TREAT_AS_LANGUAGE.includes(lang as 'aws' | 'dotnet')) return lang;
        return allLanguages.includes(lang as SupportedTargets) ? lang : false;
      })
      .filter(Boolean) as string[];

    const available = sortLanguages([...(useAllAvailableLanguages ? allLanguages : provided)]);

    // If we're not using all available languages, then we sort the provided ones?
    // The business logic behind our language picker isn't entirely clear to me,
    // but this is what our language picker unit tests wanted!
    return [available, useAllAvailableLanguages ? provided : sortLanguages(provided)];
  }, [initialLanguages, useAllAvailableLanguages]);

  // initialize data specific to form slice
  const defaultServer: ReferenceFormSliceState['schemaEditor']['data']['server'] = useMemo(() => {
    const variables = apiDefinition ? apiDefinition.defaultVariables(0) : {};
    return { selected: 0, variables };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiDefinition?.api?._id]);

  // initialize data specific to auth slice
  const groups = useMemo(
    () =>
      user?.keys &&
      user.keys.map(key => {
        const securitySchemes = Object.keys(apiDefinition?.api?.components?.securitySchemes || {});
        // We're constructing groups here which is used by the API Key dropdown in the API Executor, logs page, and
        // Your Requests page.
        const id = getIdFromKey(key, securitySchemes);

        return {
          id: id as string,
          name: key.name,
        };
      }),
    [user?.keys, apiDefinition?.api?.components?.securitySchemes],
  );

  const { storage, getStoredAuth } = useAuthStorage();
  const initialAuthData = useMemo(() => {
    let auth: ReferenceAuthSliceState['auth'] = {};
    let oauth: ReferenceAuthSliceState['oauth'] = { schemes: {} };
    let selectedAuth: ReferenceAuthSliceState['selectedAuth'] = [];
    let server: ReferenceFormSliceState['schemaEditor']['data']['server'] = defaultServer;

    if (isReady) {
      // we only calculate/initialize this auth data on initial page load.
      // if the page has already loaded, we exit early with placeholder data
      // since this data won't be sent into ReferenceStore anyways.
      return {
        auth,
        group: '',
        groupName: '',
        isGroupLoggedIn: false,
        oauth,
        selectedAuth,
        server,
      };
    }

    const initialGroupId = getGroupId(user || {}, operation);

    let group = initialGroupId || `unknown:${uuid()}`;

    const storedAuth = getStoredAuth();

    if (storedAuth) {
      try {
        if (storedAuth.auth) {
          auth = storedAuth.auth;
        }

        if (storedAuth.group) {
          // We should only use their group ID from their stored auth if it's a known group in
          // their list of groups. Otherwise if there's no user just use what's in the auth storage
          // if it's an "unknown" temp session.
          if (
            getGroupNameById(user?.keys, storedAuth.group) ||
            (user?.email == null &&
              // We no longer identify logged out users with a `noAuth` group but for backwards
              // compatibility we'll still support retrieving their unauthed status.
              (storedAuth.group.startsWith('noAuth:') || storedAuth.group.startsWith('unknown:')))
          ) {
            group = storedAuth.group;
          }
        }

        if (storedAuth.oauth) {
          oauth = storedAuth.oauth;
        }

        if (storedAuth.selectedAuth) {
          selectedAuth = storedAuth.selectedAuth;
        }

        if (storedAuth.servers) {
          server = storedAuth.servers;
        }
      } catch (e) {
        // If we were unable to decode the stored auth for whatever reason (maybe the secret was
        // lost or regenerated?) then we should purge whatever we have out of the users' storage.
        storage.removeItem('auth');
      }
    }

    const groupName = getGroupNameById(user?.keys || [], group);
    auth = {
      // Prefill the users `authData` with some defaults according to the OAS they're looking at,
      // but we should still prefer any auth data that they might have from in storage.
      ...(apiDefinition?.getAuth(user || {}, groupName || undefined) as ReferenceAuthSliceState['auth']),
      ...auth,
    };

    return {
      auth,
      group,
      groupName,
      isGroupLoggedIn: Boolean(initialGroupId),
      oauth,
      selectedAuth,
      server,
    };
  }, [apiDefinition, defaultServer, getStoredAuth, isReady, operation, storage, user]);

  const fullServerUrl = useMemo(() => {
    return apiDefinition?.url(initialAuthData.server.selected, initialAuthData.server.variables) || '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiDefinition?.api?._id, initialAuthData.server]);

  const initializeOpts: Parameters<typeof initialize>[0] = {
    apiDefinition,
    availableLanguages,
    customCodeSamples,
    fullServerUrl,
    groups,
    isOAuthRedirectPage,
    language,
    maxLanguages,
    operation,
    providedLanguages,
    supportedLanguages,
    supportsSimpleMode,
    useAllAvailableLanguages,
    ...initialAuthData,
  };

  if (!isReady) {
    initialize(initializeOpts);
  }

  useEffect(() => {
    initialize(initializeOpts);

    // after initialization, update the auth slice to reflect any api definition or operation changes
    if (apiDefinition && operation && user?.email) {
      const securitySchemeSupported = operationSupportsSecurityScheme(operation, currentSelectedAuth);
      if (!securitySchemeSupported) {
        // We should only update the groupId if the operation doesn't support the currently selected
        // auth scheme. This is so that if a user has multiple keys for the same auth scheme they
        // can navigate between references pages and not have their auth reset.
        // We want to do this regardless of whether this is the first run on not to catch the case
        // where an unsupported auth scheme is stored in local storage and the user navigates directly
        // to a reference page with an unsupported scheme.
        const groupId = getGroupId(user, operation);
        updateGroup({ apiDefinition, groupId, user });
      }
    }

    // Since we're preloading auth on the initial load we don't want to overwrite that data here.
    if (!isReady) {
      return;
    }

    // If the OAS updates we need to also refresh the users' auth state to be in accordance with
    // the auth schemes that are present in that OAS. We're also supplying the currently set
    // `groupName` into `oas.getAuth` because we don't want to reset the users' auth when they
    // switch to a page that has a different OAS.
    updateAuth(apiDefinition?.getAuth(user || {}, currentGroupName as string) as Record<string, string>, false);
    updateServer(defaultServer, apiDefinition, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiDefinition?.api?._id, operation, httpsnippetPlugins]);

  // persist any auth updates to local storage
  usePersistAuthStorage();

  return <>{children}</>;
}
