// For reasons unknown, these exported objects must live in a separate module
// than in `./Routes.tsx` or `./Island/Router`. Otherwise, test failures will
// occur specifically under `src/ui/MarkdownEditor`.

import type { MyDevelopersRouteParams } from '@core/types/metrics';

/**
 * Dictionary of all browser router paths that are defined segmented by actions
 * being performed like `view` or `edit`.
 */
export const SuperHubRoutePaths = {
  viewGuidePage: '/:section(docs)/:slug?',
  viewReferencePage: '/:section(reference)/:slug?',
  viewCustomPage: '/:section(page)/:slug?',
  viewChangelog: '/:section(changelog)/:slug?',
  viewDiscussions: '/:section(discuss)/:slug?',
  viewRecipes: '/:section(recipes)/:slug?',
  editPage: '/:action(update)/:section(changelog|docs|page|reference|recipes)/:slug?',
  createGuidePage: '/:action(create)/:section(docs)/:routeParentCategory/:routeParentPage?',
  createReferencePage: '/:action(create)/:section(reference)/:routeParentCategory/:routeParentPage?',
  createCustomPage: '/:action(create)/:section(page)',
  createChangelog: '/:action(create)/:section(changelog)',
  comparePage: '/:action(compare)/:section(changelog|docs|page|reference)/:slug/:commitHash?',
} as const;

/**
 * Dictionary of all hash router paths that are defined, segmented by actions
 * being performed like create, configure, etc.
 */
export const SuperHubHashRoutePaths = {
  appearance: '/:action(appearance)/:section*',
  myDevelopers: '/:action(my-developers)/:type*',
  settings: '/:action(configure|content)/:section*',
  createApiDefinition: '/:action(create)/:section(api-definition)',
} as const;

/**
 * Browser route params as defined by the docs routes inside ../../Hub.
 */
export interface SuperHubRouteParams {
  /**
   * Action being performed on a particular section.
   * @example /create/page -> create a new custom page
   */
  action?: 'compare' | 'create' | 'update';
  /**
   * During `compare` actions, contains the git commit hash to diff against.
   * @example /compare/docs/getting-started/75a2919zdz
   */
  commitHash?: string;
  /**
   * During `create` actions for docs and reference sections only, indicates the
   * parent category `title` for the new page being created.
   * @example /create/docs/Documentation
   */
  routeParentCategory?: string;
  /**
   * During `create` actions for docs and reference sections only, indicates the
   * parent page `slug` for the new sub-page being created.
   * @example /create/docs/Documentation/how-to-fly
   */
  routeParentPage?: string;
  /**
   * Documentation section currently being viewed or acted upon.
   */
  section?: 'changelog' | 'discuss' | 'docs' | 'page' | 'recipes' | 'reference';
  /**
   * Unique document slug that is currently being viewed or acted upon.
   */
  slug?: string;
}

export interface SuperHubHashRouteParams {
  /**
   * Top-level action that is currently being performed. This is the initial
   * segment of the route that describes what mode we are in.
   * - `create`: When creating "something" new.
   * - `appearance`: When configuring project settings with live-preview forms
   * for things like the theme editor and custom CSS/JS.
   * - `configure`: When configuring project settings like content, authentication,
   * team members, etc.
   * - 'content': When managing content like reusable content, glosary, etc.
   */
  action?: 'appearance' | 'configure' | 'content' | 'create' | 'my-developers';

  /**
   * MyDevelopers specific subroute type
   */
  identifier?: MyDevelopersRouteParams['identifier'];

  /**
   * Top-level section within a particular action.
   */
  section?: string;

  /**
   * MyDevelopers specific subroute type
   */
  type?: MyDevelopersRouteParams['type'];
}
