export const defaultOpenAIAnswer = 'Here are some references to check out:';
export const customDefaultAnswer = "Owlbot AI didn't have an answer for this question.";
export const defaultChunkOverlap = 20;
export const defaultChunkSize = 256;
export const gpt4Encoding = 'cl100k_base'; // sometimes this notation is used for gpt 4 encoding
// data from: https://openai.com/blog/new-models-and-developer-products-announced-at-devday
export const gptModel = 'gpt-4o';
export const currentTokenLimit = 32000;

export const FREE_OWLBOT_LIMIT = 100;
export const didOwlbotAnswer = (givenAnswer: string, projectDefaultAnswer: string) =>
  givenAnswer !== (projectDefaultAnswer || defaultOpenAIAnswer);
export const owlbotLimitAnswer = 'The free Owlbot limit has been exceeded for this project.';
