/**
 * A global @fixme alias for `any` types.
 *
 */
export type $TSFixMe = any;

/**
 * When this type is provided a type alias, it will unroll it into the specific object. This is useful to provide meaningful mouseover hints.
 * I wish that VScode would just do this for us.
 *
 * e.g.
 * ```
 * type ABC = { a: string };
 * const a: ABC;
 * const b: ExpandRecursively<ABC>;
 * ```
 *
 * mouseover `a` and you'll see it is the type ABC.
 * mouseover `b` and you'll see it is the type { a: string }
 */
export type ExpandRecursively<T> = T extends (...args: infer A) => infer R
  ? (...args: ExpandRecursively<A>) => ExpandRecursively<R>
  : T extends object
    ? T extends infer O
      ? { [K in keyof O]: ExpandRecursively<O[K]> }
      : never
    : T;

export type {
  LegacyRDMDOpts,
  LegacyRDMDProps,
  Opts as RDMDOpts,
  Props as RDMDProps,
  RMDXOpts,
  RMDXProps,
} from './rdmd';

export * from './hub';
export * from './models';
export * from './project';
export * from './chatgpt';
export * from './auth';
