import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';

let sidebarCategoryCount = 0;

/**
 * Returns a mock git sidebar category for testing purposes.
 */
export function createMockSidebarCategory(keys: Partial<GitSidebarCategory> = {}): GitSidebarCategory {
  sidebarCategoryCount += 1;
  const title = keys.title ?? `Category ${sidebarCategoryCount}`;
  return {
    pages: [],
    title,
    uri: `/versions/1.0.0/categories/guides/${title}`,
    ...keys,
  };
}

let sidebarPageCount = 0;
/**
 * Returns a mock git sidebar page for testing purposes.
 */
export function createMockSidebarPage(keys: Partial<GitSidebarPage> = {}): GitSidebarPage {
  sidebarPageCount += 1;
  const slug = keys.slug ?? `slug-${sidebarPageCount}`;
  return {
    pages: [],
    deprecated: false,
    hidden: false,
    isBodyEmpty: false,
    parent: null,
    category: '/versions/1.0.0/categories/guides/Documentation',
    slug,
    title: `Page ${sidebarPageCount}`,
    type: 'basic',
    updatedAt: new Date(),
    uri: `/versions/1.0.0/guides/${slug}`,
    ...keys,
  };
}

export const mockRealtimeCategory = createMockSidebarCategory({
  pages: [
    createMockSidebarPage({ slug: 'getting-started', title: 'Getting Started', type: 'endpoint' }),
    createMockSidebarPage({ slug: 'authentication', title: 'Authentication', type: 'endpoint' }),
    createMockSidebarPage({ slug: 'my-requests', title: 'My Requests', type: 'basic' }),
  ],
  title: 'Developer Dashboard',
});
